import React, { useEffect, useState } from 'react'
import TextArea from 'antd/es/input/TextArea';
import { Badge, Button, Col, Form, FormInstance, Input, message, Modal, Popconfirm, Result, Row, Select, Space, Spin, Switch, Table, Tooltip, Upload } from 'antd';
import { SaveOutlined, UploadOutlined } from '@ant-design/icons';
import { BASE_URL } from '../../../configs/api/api';
import { useUpdateArticleByIdMutation } from '../../../store/articles/articles.service';

const SubmitButton = ({ form }) => {
    const [submittable, setSubmittable] = React.useState(false);
    const values = Form.useWatch([], form);

    React.useEffect(() => {
        form.validateFields({ validateOnly: true }).then(
            () => {
                setSubmittable(true);
            },
            () => {
                setSubmittable(false);
            },
        );
    }, [values]);

    return (
        <Button type="primary" htmlType="submit" disabled={!submittable} className='bg-blue-500'>
            <SaveOutlined></SaveOutlined> Lưu thông tin
        </Button>
    );
};

const ModalArticleUpdate = (props) => {
    // form
    const [form] = Form.useForm()
    const [file, setFile] = useState([]);
    const { openUpdate, setOpenUpdate, getOneArticle, listApartment } = props
    const [onUpdate] = useUpdateArticleByIdMutation()
    useEffect(() => {
        if (getOneArticle) {
            const formattedLogo = getOneArticle.image
                ? [{
                    uid: 'logo', // Unique identifier
                    name: 'logo.jpg', // Tên file (có thể lấy từ URL hoặc đặt mặc định)
                    status: 'done', // Trạng thái hoàn thành
                    url: `${BASE_URL}/${getOneArticle.image}`, // URL ảnh

                }]
                : [];
            form.setFieldsValue({
                title: getOneArticle.title,
                describle: getOneArticle.describle,
                apartment_id: getOneArticle.apartment_id,
                image: formattedLogo
            })
        }
    }, [getOneArticle, openUpdate])
    const handleCancel = () => {
        setOpenUpdate(false);
        form.resetFields()
    };

    // submit add phòng ban
    const onFinish = async (values) => {
        try {
            if (getOneArticle) {
                const formData = new FormData();
                formData.append("id", getOneArticle.id)
                formData.append("title", values.title)
                formData.append("describle", values.describle)
                formData.append("apartment_id", values.apartment_id)
                if (file.length > 0) {
                    file.forEach((item) => {
                        formData.append("image", item.originFileObj)
                    })
                } else {
                    formData.append("image", getOneArticle?.image)
                }
                const results = await onUpdate(formData)
                if (results.error) {
                    message.error(`${results.error.data.message}`)
                    return
                }
                message.success(`${results.data.message}`);
                form.resetFields()
                setOpenUpdate(false);
            } else {
                message.warning("Không tìm thấy bài viết này")
            }
        } catch (error) {
            console.log(error)
        }
    }
  
    // modal
    const handleOk = () => {
        setOpenUpdate(false);
    };
    const handleFileChangeFile = ({ fileList }) => setFile(fileList);

    const optionsListApartments = listApartment?.map((item) => ({
        label: item.name, // Hoặc thuộc tính tương ứng từ item
        value: item.id, // Hoặc thuộc tính tương ứng từ item
        // disabled: !item.active
    }));
    return (
        <div>
            {/* modal them */}
            <Modal
                title="Cập nhật dịch vụ"
                open={openUpdate}
                width={800}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
            >
                <Form
                    form={form}
                    name="validateOnly"
                    layout="vertical"
                    style={{
                        width: "100%",
                        margin: 0,
                        padding: "20px"
                    }}
                    autoComplete="off"
                    onFinish={onFinish}
                    className="mx-auto"
                >
                    {/* Row */}
                    <Row gutter={12}>
                        <Col span={24}>
                            <Form.Item
                                label="Ảnh bài viết"
                                name="image"
                                valuePropName="fileList"
                                getValueFromEvent={(e) => e.fileList}
                            >
                                <Upload
                                    // multiple
                                    maxCount={1}
                                    listType="picture-card"
                                    fileList={file}
                                    beforeUpload={() => false}  // Không upload ngay lập tức
                                    onChange={handleFileChangeFile}
                                >
                                    <Button icon={<UploadOutlined />}></Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                        {/* name */}
                        <Col span={16}>
                            <Form.Item name="title" label="Tiêu đề bài viết" rules={[
                                { required: true, message: '* Không được để trống' }
                            ]}>
                                <Input placeholder='Tiêu đề bài viết ...' />
                            </Form.Item>
                        </Col>
                        {/* apartment */}
                        <Col span={8}>
                            <Form.Item name="apartment_id" label="Bài viết về phòng ban" rules={[
                                { required: true, message: '* Không được để trống' }
                            ]}>
                                <Select
                                    // mode="multiple"
                                    style={{ width: '100%' }}
                                    placeholder="Chọn phòng ban"
                                    options={optionsListApartments}
                                />
                            </Form.Item>
                        </Col>
                        {/* describle */}
                        <Col span={24}>
                            <Form.Item name="describle" label="Mô tả" rules={[
                                { required: true, message: '* Không được để trống' }
                            ]}>
                                <TextArea placeholder='Mô tả ...'></TextArea>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Space style={{
                        display: "flex",
                        justifyContent: "flex-end"
                    }}>
                        <SubmitButton form={form} />
                    </Space>
                </Form>
            </Modal>
        </div>
    )
}

export default ModalArticleUpdate
