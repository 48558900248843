import React, { useState } from 'react'
import {
    ApartmentOutlined,
    AppstoreOutlined,
    AuditOutlined,
    BarsOutlined,
    CalendarOutlined,
    ContainerOutlined,
    CreditCardOutlined,
    FieldTimeOutlined,
    FundViewOutlined,
    HomeOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    PictureOutlined,
    PieChartOutlined,
    ProductOutlined,
    ScheduleOutlined,
    TranslationOutlined,
    UploadOutlined,
    UserAddOutlined,
    UserOutlined,
    UserSwitchOutlined,
    VideoCameraOutlined,
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';
const { Header, Sider, Content } = Layout;
function getItem(
    label,
    key,
    icon,
    children
  ) {
    return {
      key,
      icon,
      children,
      label,
    } 
  }
const MenuComponents = (props) => {
    const { collapsed } = props
    const items = [
        getItem(<Link to={"/admin"}>Thống kê</Link >, '1', <PieChartOutlined />),
        getItem(<Link to={"/admin/services"}>Dịch vụ</Link >, '2', <ContainerOutlined />),
        getItem(<Link to={"/admin/banner"}>Banner</Link >, '6', <PictureOutlined />),
        getItem("Bài viết", "sub1", <TranslationOutlined />,[
            getItem(<Link to={"/admin/articles"}>Quản lí bài viết</Link >, '5', ),
            getItem(<Link to={"/admin/services-articles"}>Quản lí công việc</Link >, '4')
        ]),
        getItem(<Link to={"/admin/apartments"}>Quản lí phòng ban</Link >, '3',<ApartmentOutlined />),
        getItem(<Link to={"/admin/contacts"}>Khách hàng</Link >, '6',<UserAddOutlined />),
      ];
    return (
        <div>
            <Sider trigger={null} collapsible collapsed={collapsed} style={{
                minHeight: "100vh",
                width: "400px"
            }}>
                <Link to={"/"} className="w-[100%] flex justify-center items-center">
                    <img className='w-20 h-20' src="/imgs/logo/logo.png" alt="" />
                </Link>
                <Menu
                    theme="dark"
                    mode="inline"
                    defaultSelectedKeys={['1']}
                    items={items}
                />
            </Sider>
        </div>
    )
}

export default MenuComponents
