import { DeleteFilled, EditFilled } from "@ant-design/icons";
import { Button, message, Modal, Popconfirm, Space, Table, Tooltip } from "antd";
import { useEffect, useState } from "react";
import ModalApartmentCreate from "./ModalCreateApartment";
import ModalApartmentUpdate from "./ModalUpdateApartment";
import { useDeleteApartmentByIdMutation, useFetchAllApartmentQuery, useLazyGetByApartmentIdQuery } from "../../../store/apartment/apartment.service";

const ApartmentComponents = () => {
    // modal state 
    const [open, setOpen] = useState(false);
    const [openUpdate, setOpenUpdate] = useState(false);
    const { data: listApartment, isError } = useFetchAllApartmentQuery()
    const [trigger, { data: getOneApartment, isError: isErrorGetOne }] = useLazyGetByApartmentIdQuery()
    const [onDelete] = useDeleteApartmentByIdMutation()
    useEffect(() => {
        if (isError || isErrorGetOne) {
            message.warning("Lỗi khi hiển thị danh sách phòng ban");
        }
    }, [isError, isErrorGetOne]); // Chạy effect khi isErrorlistApartment thay đổi
    const data = listApartment ? listApartment.map((apartment, index) => ({
        key: index + 1,
        id: apartment.id,
        name: apartment.name,
    })) : []
    const columns = [
        {
            title: 'STT',
            dataIndex: 'key',
        },
        {
            title: 'Tên phòng ban',
            dataIndex: 'name',
        },
        {
            title: 'Hành động',
            key: 'action',
            width: 150,
            render: (_, value) => (
                <Space size="middle" className='flex justify-start'>
                    <Tooltip title="Chỉnh sửa" color={'yellow'} key={'yellow'}>
                        <EditFilled className='text-xl text-yellow-400' onClick={() => showModalUpdate(value.id)} />
                    </Tooltip>
                    <Popconfirm
                        title="Xóa"
                        description={`Bạn có chắc muốn xóa: ${value.name}`}
                        onConfirm={() => handleDelete(value.id)}
                        okText="Yes"
                        cancelText="No"
                        okButtonProps={{ className: "text-white bg-blue-500" }}
                    >
                        <Tooltip title="Xóa" color={'red'} key={'red'}>
                            <DeleteFilled className='text-xl text-red-500' />
                        </Tooltip>
                    </Popconfirm>
                </Space>
            )
        },
    ];
    // nut xoa
    const handleDelete = async (id) => {
        const results = await onDelete(id)
        if (results.error) {
            message.error(`${results.error.data.message}`)
            return
        }
        message.success(`${results.data.message}`);
    }
    // create modal
    const showModal = () => {
        setOpen(true);
    };
    // update modal
    const showModalUpdate = (id) => {
        if(id){
            trigger(id)
        }
        setOpenUpdate(true);
    };

    return (
        <>
            <div className="flex items-center gap-2 justify-between">
                <h3 className='text-title mb-0'>Quản lí phòng ban</h3>
                <Button type='primary' className='mb-3' onClick={() => showModal()}>Thêm mới</Button>
            </div>
            <Table dataSource={data} columns={columns} />
            {/* modal create */}
            <ModalApartmentCreate setOpen={setOpen} open={open}></ModalApartmentCreate>
            <ModalApartmentUpdate getOneApartment={getOneApartment ? getOneApartment : {}} setOpenUpdate={setOpenUpdate} openUpdate={openUpdate}></ModalApartmentUpdate>
        </>
    )
}

export default ApartmentComponents
