import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import contactImg from "../assets/img/contact-img.svg";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { Form, Input, message } from "antd";
import { useCreateContactMutation } from "../store/contact/contact.service";
import { useForm } from "antd/es/form/Form";
import TextArea from "antd/es/input/TextArea";
import 'antd/dist/reset.css'; // Hoặc 'antd/dist/antd.css' tùy vào phiên bản bạn sử dụng
export const Contact = () => {
  const [form] = useForm()
  const [onAdd] = useCreateContactMutation()
  const [buttonText, setButtonText] = useState('Gửi');
  const [status, setStatus] = useState({});
const [alert,setAlert] = useState("")
const [alertErr,setAlertErr] = useState("")

  const onFinish = async (values) => {
    setButtonText("Đang gửi...");
    try {
      const results = await onAdd(values).unwrap(); // Sử dụng unwrap() nếu bạn đang dùng RTK Query
      setAlert(`${results.message}`)
      setTimeout(() => {
        setAlert(``)
      },3000)
      form.resetFields();
    } catch (error) {
      setAlertErr(`${error.data?.message || "Gửi thất bại!"}`)
      setTimeout(() => {
        setAlertErr(``)
      },1000)
    } finally {
      setButtonText("Gửi");
    }
  };
  

  return (
    <section className="contact" id="connect">
      <Container>
        <Form
          form={form}
          name="validateOnly"
          layout="vertical"
          style={{
            width: "100%",
            margin: 0,
            padding: "20px"
          }}
          autoComplete="off"
          onFinish={onFinish}
          className="mx-auto"
        >
          <Row className="align-items-center">
            <Col size={12} md={5} className="hidden md:block">
              <TrackVisibility>
                {({ isVisible }) =>
                  <img className={isVisible ? "animate__animated animate__zoomIn" : ""} src={contactImg} alt="Contact Us" />
                }
              </TrackVisibility>
            </Col>
            <Col size={12} md={7}>
              <TrackVisibility>
                {({ isVisible }) =>
                  <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                    <h2 className="text-white">Liên hệ với chúng tôi</h2>
                    <Row>
                      <Col size={12} sm={12} className="px-1">
                        <Form.Item name="full_name">
                          <Input placeholder="Họ và tên ..."></Input>
                        </Form.Item>
                        {/* <input type="text" value={formDetails.firstName} placeholder="Họ và tên" onChange={(e) => onFormUpdate('full_name', e.target.value)} /> */}
                      </Col>
                      <Col size={12} sm={6} className="px-1">
                        <Form.Item name="email">
                          <Input type="email" placeholder="VD: abc@gmail.com"></Input>
                        </Form.Item>
                      </Col>
                      <Col size={12} sm={6} className="px-1">
                        <Form.Item name="tel">
                          <Input placeholder="Số điện thoại"></Input>
                        </Form.Item>
                      </Col>
                      <Col size={12} className="px-1">
                        <Form.Item name="message">
                          <TextArea placeholder="Lời nhắn"></TextArea>
                        </Form.Item>
                        <button ><span>{buttonText}</span></button>
                        <span className="text-green-500 font-bold ml-3">{alert}</span>
                        <span className="text-red-500 font-bold ml-3">{alertErr}</span>
                      </Col>
                      {
                        status.message &&
                        <Col>
                          <p className={status.success === false ? "danger" : "success"}>{status.message}</p>
                        </Col>
                      }
                    </Row>
                  </div>}
              </TrackVisibility>
            </Col>
          </Row>
        </Form>
      </Container>
    </section>
  )
}
