import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";
import logo from "../assets/img/logo.svg";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Space } from "antd";
import { EnvironmentOutlined, PhoneOutlined } from "@ant-design/icons";
import { Envelope } from "react-bootstrap-icons";

export const Footer = () => {
  const navigate = useNavigate()
  let location = useLocation();
  const [pathName, setPathName] = useState(null)
  useEffect(() => {
    if (location.pathname) {
      const patchStr = location.pathname
      const result = patchStr.replace(/\//g, "");
      setPathName(result)
    }
  }, [navigate])
  const handelNavigate = () => {
    window.open("https://lamhai.com.vn/", "_blank")
  }
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center justify-between">
          <MailchimpForm />
          <Col className="p-0" size={12} md={2}>
            <img src="/imgs/logo/logo.png" className="cursor-pointer w-[60%] md:w-[80%]" alt="Logo" onClick={() => handelNavigate()} />
          </Col>
          <Col className="p-0" size={12} md={5} xl={7}>
            <div className="ml-[50%] md:ml-0 -translate-x-1/2 md:translate-x-0 text-[12px]">
              <h4 className="">Thông tin chúng tôi</h4>
              <div className="mt-2">
                <Space className="flex items-center mt-1">
                  <EnvironmentOutlined />
                  <p className="m-0">Số 35, ngách 93/15, Yên Sở, Hoàng Mai, Hà Nội</p>
                </Space>
                <Space className="flex items-center mt-1">
                  <PhoneOutlined />
                  <p className="m-0">0838 882 883</p>
                </Space>
                <Space className="flex items-center mt-1">
                  <Envelope></Envelope>
                  <p className="m-0">info@lamhai.net</p>
                </Space>
              </div>
            </div>
          </Col>
          <Col className="p-0 hidden lg:block" size={12} lg={5} xl={3}>
            <div className="iframe-container">
              <iframe
                src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Flamhai.com.vn%3Fref%3Dembed_page&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=665149288077563"
                className="iframe-content"
                frameBorder="0"
                allowFullScreen={true}
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                title="Facebook Page"
              ></iframe>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
