export const routes = {
    home: "/",
    pageNotFound: "*"
}

export const routesAdmin = {
    dashboard: "/admin",
    adminCategories: "/admin/categories",
    adminServiceArticle: "/admin/services-articles",
    adminServices: "/admin/services",
    adminApartments: "/admin/apartments",
    adminArticles: "/admin/articles",
    adminContacts: "/admin/contacts",
    adminBanner: "/admin/banner",
    pageNotFound: "*"
}