import { createSlice } from "@reduxjs/toolkit";

export const initialStateScoreFile = {
    arhticles: []
}

export const initialSearchState = {
    searchTerm: "",
    arhticles: []
}

const arhticleByApartmentSlice = createSlice({
    name: "arhticles",
    initialState: initialStateScoreFile || initialSearchState,
    reducers: ({
        fetchAllArhticleSlice: (state, actions) => {
            state.arhticles = actions.payload
        },
        fetchAllArhticleByApartmentSlice: (state, actions) => {
            state.arhticles = state.arhticles.filter((arhticle) => arhticle.apartment_id === actions.payload)
        },
    })
})

export const { fetchAllArhticleSlice, fetchAllArhticleByApartmentSlice } = arhticleByApartmentSlice.actions
export default arhticleByApartmentSlice.reducer