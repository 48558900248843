import { DeleteFilled, EditFilled } from "@ant-design/icons";
import { Button, Image, message, Modal, Popconfirm, Space, Table, Tooltip } from "antd";
import { useEffect, useState } from "react";
import ModalArticleCreate from "./ModalCreateArticle";
import ModalArticleUpdate from "./ModalUpdateArticle";
import { useDeleteArticleByIdMutation, useFetchAllArticleQuery, useLazyGetByArticleIdQuery } from "../../../store/articles/articles.service";
import { BASE_URL } from "../../../configs/api/api";
import { useFetchAllApartmentQuery } from "../../../store/apartment/apartment.service";


const ArticleComponents = () => {
    // modal state 
    const [open, setOpen] = useState(false);
    const [openUpdate, setOpenUpdate] = useState(false);
    const { data: listArticle, isError: isErrListArticle } = useFetchAllArticleQuery()
    const { data: listApartment, isError: isErrListApartment } = useFetchAllApartmentQuery()
    const [trigger, { data: getOneArticle, isErGetOne }] = useLazyGetByArticleIdQuery()
    const [onDelete] = useDeleteArticleByIdMutation()
    useEffect(() => {
        if (isErrListArticle || isErrListApartment || isErGetOne) {
            message.warning("Xảy ra lỗi!");
        }
    }, [isErrListArticle, isErrListApartment, isErGetOne]); // Chạy effect khi 
    const data = listArticle ? listArticle.map((article, index) => ({
        key: index + 1,
        id: article.id,
        image: article.image,
        title: article.title,
        describle: article.describle,
        content: article.content,
    })) : []
    const columns = [
        {
            title: 'STT',
            dataIndex: 'key',
        },
        {
            title: 'Ảnh',
            dataIndex: 'image',
            render: (_, value) => (
                <>
                    <Image width={80} height={80} className="rounded-[50%]" src={`${BASE_URL}/${value.image}`}></Image>
                </>
            )
        },
        {
            title: 'Tiêu đề',
            dataIndex: 'title',
        },
        {
            title: 'Mô tả',
            dataIndex: 'describle',
        },
        {
            title: 'Hành động',
            key: 'action',
            width: 150,
            render: (_, value) => (
                <Space size="middle" className='flex justify-start'>
                    <Tooltip title="Chỉnh sửa" color={'yellow'} key={'yellow'}>
                        <EditFilled className='text-xl text-yellow-400' onClick={() => showModalUpdate(value.id)} />
                    </Tooltip>
                    <Popconfirm
                        title="Xóa"
                        description={`Bạn có chắc muốn xóa: ${value.name}`}
                        onConfirm={() => handleDelete(value.id)}
                        okText="Yes"
                        cancelText="No"
                        okButtonProps={{ className: "text-white bg-blue-500" }}
                    >
                        <Tooltip title="Xóa" color={'red'} key={'red'}>
                            <DeleteFilled className='text-xl text-red-500' />
                        </Tooltip>
                    </Popconfirm>
                </Space>
            )
        },
    ];
    // xoa
    const handleDelete = async (id) => {
        const results = await onDelete(id)
        if (results.error) {
            message.error(`${results.error.data.message}`)
            return
        }
        message.success(`${results.data.message}`);
    }
    // create modal
    const showModal = () => {
        setOpen(true);
    };
    // update modal
    const showModalUpdate = (id) => {
        if (id) {
            trigger(id)
        }
        setOpenUpdate(true);
    };

    return (
        <>
            <div className="flex items-center gap-2 justify-between">
                <h3 className='text-title mb-0'>Quản lí bài viết</h3>
                <Button type='primary' className='mb-3' onClick={() => showModal()}>Thêm mới</Button>
            </div>
            <Table dataSource={data} columns={columns} />
            {/* modal create */}
            <ModalArticleCreate listApartment={listApartment ? listApartment : []} setOpen={setOpen} open={open}></ModalArticleCreate>
            <ModalArticleUpdate getOneArticle={getOneArticle ? getOneArticle : {}} listApartment={listApartment ? listApartment : []} setOpenUpdate={setOpenUpdate} openUpdate={openUpdate}></ModalArticleUpdate>
        </>
    )
}

export default ArticleComponents
