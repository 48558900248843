import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../configs/api/api";

const articleApi = createApi({
    reducerPath: "articles",
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
        credentials: 'include'
    }),
    tagTypes: ["articles"],
    endpoints: (builder) => ({
        fetchAllArticle: builder.query({
            query: () => `/api/get-all-article`,
            providesTags: ["articles"]
        }),
        getByArticleId: builder.query({
            query: (id) => `/api/${id}/get-one-article`,
            providesTags: ["articles"]
        }),
        createArticles: builder.mutation({
            query: (data) => ({
                url: `/api/create-article`,
                method: "POST",
                body: data
            }),
            invalidatesTags: ["articles"]
        }),
        updateArticleById: builder.mutation({
            query: (data) => ({
                url: `/api/update-article`,
                method: "PATCH",
                body: data
            }),
            invalidatesTags: ["articles"]
        }),
        // Xoa theo id
        deleteArticleById: builder.mutation({
            query: (id) => ({
                url: `/api/${id}/delete-article`,
                method: "DELETE"
            }),
            invalidatesTags: ["articles"]
        }),
    })
})

export const {useFetchAllArticleQuery,useDeleteArticleByIdMutation,useCreateArticlesMutation,useUpdateArticleByIdMutation,useLazyGetByArticleIdQuery} = articleApi
export default articleApi