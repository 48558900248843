import React, { useEffect, useRef, useState } from 'react'
import TextArea from 'antd/es/input/TextArea';
import { Badge, Button, Col, Form, FormInstance, Input, message, Modal, Popconfirm, Result, Row, Select, Space, Spin, Switch, Table, Tooltip, Upload } from 'antd';
import { SaveOutlined, UploadOutlined } from '@ant-design/icons';
import { useCreateArticleServiceMutation } from '../../../store/article_service/articleService.service';
import { Editor } from '@tinymce/tinymce-react';
import TinyMCEEditor from '../../clients/TinyMCEEditor';
const SubmitButton = ({ form }) => {
    const [submittable, setSubmittable] = React.useState(false);
    const values = Form.useWatch([], form);

    React.useEffect(() => {
        form.validateFields({ validateOnly: true }).then(
            () => {
                setSubmittable(true);
            },
            () => {
                setSubmittable(false);
            },
        );
    }, [values]);

    return (
        <Button type="primary" htmlType="submit" disabled={!submittable} className='bg-blue-500'>
            <SaveOutlined></SaveOutlined> Lưu thông tin
        </Button>
    );
};


const ModalCreateServiceArticle = (props) => {
    // form
    const { open, setOpen, listArticle } = props
    const [value, setValue] = useState('');
    const [form] = Form.useForm()
    const editorRef = useRef(null);
    const [onAdd] = useCreateArticleServiceMutation()
    const handleCancel = () => {
        setOpen(false);
        form.resetFields()
    };

    // submit add the loai
    const onFinish = async (values) => {
        try {
          
            const results = await onAdd(values)
            if (results.error) {
                message.error(`${results.error.data.message}`)
                return
            }
            message.success(`${results.data.message}`);
            form.resetFields()
            setOpen(false);
        } catch (error) {
            console.log(error)
        }
    }
    // modal
    const handleOk = () => {
        setOpen(false);
    };
    // ảnh đại diện

    const optionsListApartments = listArticle?.map((item) => ({
        label: item.apartment_name, // Hoặc thuộc tính tương ứng từ item
        value: item.id, // Hoặc thuộc tính tương ứng từ item
        // disabled: !item.active
    }));
    return (
        <div>
            {/* modal them */}
            <Modal
                title="Thêm mới công việc"
                open={open}
                width={950}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
            >
                <Form
                    form={form}
                    name="validateOnly"
                    layout="vertical"
                    style={{
                        width: "100%",
                        margin: 0,
                        padding: "20px"
                    }}
                    autoComplete="off"
                    onFinish={onFinish}
                    className="mx-auto"
                >
                    <Row gutter={25}>
                        <Col span={16}>
                            <Form.Item name="name" label="Tên công việc" rules={[
                                { required: true, message: '* Không được để trống' }
                            ]}>
                                <Input placeholder='Tên công việc ...' />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="article_id" label="Phòng ban" rules={[
                                { required: true, message: '* Không được để trống' }
                            ]}>
                                <Select
                                    // mode="multiple"
                                    style={{ width: '100%' }}
                                    placeholder="Chọn phòng ban ..."
                                    options={optionsListApartments}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="describle" label="Mô tả ngắn" rules={[
                                { required: true, message: '* Không được để trống' }
                            ]}>
                                <TextArea></TextArea>
                            </Form.Item>
                        </Col>
                        {/* content */}
                        <Col xs={24} md={24}>
                            <Form.Item name="content" label="Mô tả chi tiết" >
                            <TinyMCEEditor
                                value={form.getFieldValue('content') || ''}
                                onChange={(content) => {
                                    form.setFieldsValue({ content });
                                }}
                            />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Space style={{
                        display: "flex",
                        justifyContent: "flex-end"
                    }}>
                        <SubmitButton form={form} />
                    </Space>
                </Form>
            </Modal>
        </div>
    )
}

export default ModalCreateServiceArticle
