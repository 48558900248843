import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import logo from '../assets/img/logo.svg';
import navIcon1 from '../assets/img/nav-icon1.svg';
import navIcon2 from '../assets/img/nav-icon2.svg';
import navIcon3 from '../assets/img/nav-icon3.svg';
import { HashLink } from 'react-router-hash-link';
import {
  BrowserRouter as Router
} from "react-router-dom";

export const NavBar = () => {

  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [])

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  }
  const handelNavigate = () => {
    window.open("https://lamhai.com.vn/","_blank")
  }
  return (
    <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
      <Container>
        <Navbar.Brand href="/">
          <img className="w-[70px]" onClick={() => handelNavigate()} src="/imgs/logo/logo.png" alt="Logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <span className="navbar-toggler-icon"></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link href="#home" className={`${activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} text-[12px] lg:text-[14px]`} onClick={() => onUpdateActiveLink('home')}>Trang chủ</Nav.Link>
            <Nav.Link href="#skills" className={`${activeLink === 'skills' ? 'active navbar-link' : 'navbar-link'} text-[12px] lg:text-[14px]`} onClick={() => onUpdateActiveLink('skills')}>Dịch vụ</Nav.Link>
            <Nav.Link href="#projects" className={`${activeLink === 'projects' ? 'active navbar-link' : 'navbar-link'} text-[12px] lg:text-[14px]`} onClick={() => onUpdateActiveLink('projects')}>Lĩnh vực</Nav.Link>
          </Nav>
          <span className="navbar-text">
            {/* <div className="social-icon">
              <a href="#"><img src={navIcon1} alt="" /></a>
              <a href="#"><img src={navIcon2} alt="" /></a>
              <a href="#"><img src={navIcon3} alt="" /></a>
            </div> */}
            <HashLink to='#connect'>
              <button className="vvd uppercase text-[14px]"><span>Liên hệ ngay</span></button>
            </HashLink>
          </span>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}
