import React, { useEffect, useRef, useState } from 'react';
import {
    Badge,
    Button,
    Col,
    Form,
    Input,
    message,
    Modal,
    Row,
    Select,
    Space
} from 'antd';
import { SaveOutlined } from '@ant-design/icons';

import { useUpdateArticleServiceByIdMutation } from '../../../store/article_service/articleService.service';
import TinyMCEEditor from '../../clients/TinyMCEEditor';

// SubmitButton Component
const SubmitButton = ({ form }) => {
    const [submittable, setSubmittable] = useState(false);
    const values = Form.useWatch([], form);

    useEffect(() => {
        form
            .validateFields({ validateOnly: true })
            .then(() => setSubmittable(true))
            .catch(() => setSubmittable(false));
    }, [values, form]);

    return (
        <Button
            type="primary"
            htmlType="submit"
            disabled={!submittable}
            className='bg-blue-500'
        >
            <SaveOutlined /> Lưu thông tin
        </Button>
    );
};

// ModalUpdateServiceArticle Component
const ModalUpdateServiceArticle = (props) => {
    const [form] = Form.useForm();
    const { openUpdate, setOpenUpdate, getOneService, listArticle } = props;
    const [onUpdate] = useUpdateArticleServiceByIdMutation();

    // Set form fields when getOneService changes
    useEffect(() => {
        if (getOneService) {
            form.setFieldsValue({
                name: getOneService.name,
                describle: getOneService.describle,
                content: getOneService.content,
                article_id: getOneService.article_id
            });
        }
    }, [getOneService, openUpdate, form]);

    // Handle Modal Cancel
    const handleCancel = () => {
        setOpenUpdate(false);
        form.resetFields();
    };

    // Handle Form Submission
    const onFinish = async (values) => {
        try {
        
            const results = await onUpdate({ id: getOneService.id, ...values });
            if (results.error) {
                message.error(`${results.error.data.message}`);
                return;
            }
            message.success(`${results.data.message}`);
            form.resetFields();
            setOpenUpdate(false);
        } catch (error) {
            console.error(error);
            message.error('Đã xảy ra lỗi. Vui lòng thử lại.');
        }
    };

    // Prepare Options for Select
    const optionsListApartments = listArticle?.map((item) => ({
        label: item.apartment_name,
        value: item.id,
    }));

    return (
        <Modal
            title="Cập nhật công việc"
            open={openUpdate}
            width={950}
            onCancel={handleCancel}
            footer={null}
            destroyOnClose
        >
            <Form
                form={form}
                name="validateOnly"
                layout="vertical"
                style={{ width: "100%", margin: 0, padding: "20px" }}
                autoComplete="off"
                onFinish={onFinish}
                initialValues={{ content: getOneService?.content || "" }}
            >
                <Row gutter={25}>
                    {/* Tên công việc */}
                    <Col span={16}>
                        <Form.Item
                            name="name"
                            label="Tên công việc"
                            rules={[{ required: true, message: '* Không được để trống' }]}
                        >
                            <Input placeholder='Tên công việc ...' />
                        </Form.Item>
                    </Col>

                    {/* Phòng ban */}
                    <Col span={8}>
                        <Form.Item
                            name="article_id"
                            label="Phòng ban"
                            rules={[{ required: true, message: '* Không được để trống' }]}
                        >
                            <Select
                                style={{ width: '100%' }}
                                placeholder="Chọn phòng ban ..."
                                options={optionsListApartments}
                            />
                        </Form.Item>
                    </Col>

                    {/* Mô tả ngắn */}
                    <Col span={24}>
                        <Form.Item
                            name="describle"
                            label="Mô tả ngắn"
                            rules={[{ required: true, message: '* Không được để trống' }]}
                        >
                            <Input.TextArea rows={4} placeholder='Mô tả ngắn ...' />
                        </Form.Item>
                    </Col>

                    {/* Mô tả chi tiết */}
                    <Col span={24}>
                        <Form.Item
                            name="content"
                            label="Mô tả chi tiết"
                            rules={[{ required: true, message: '* Không được để trống' }]}
                        >
                            <TinyMCEEditor
                                value={form.getFieldValue('content') || ''}
                                onChange={(content) => {
                                    form.setFieldsValue({ content });
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                {/* Submit Button */}
                <Space style={{ display: "flex", justifyContent: "flex-end" }}>
                    <SubmitButton form={form} />
                </Space>
            </Form>
        </Modal>
    );
};

export default ModalUpdateServiceArticle;
