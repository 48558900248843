import React, { useEffect } from 'react'
import { Banner } from '../../components/Banner'
import { Skills } from '../../components/Skills'
import { Projects } from '../../components/Projects'
import { Contact } from '../../components/Contact'
import { useFetchAllServicesQuery } from '../../store/myService/service.service'
import { message } from 'antd'
import { useFetchAllApartmentQuery } from '../../store/apartment/apartment.service'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAllApartmentSlice } from '../../store/apartment/apartmentSlice'
import { useFetchAllBannerQuery } from '../../store/banner/banner.service'

const HomeComponents = () => {
  const dispatch = useDispatch()
  const {data: listService,isError: isErrorService} = useFetchAllServicesQuery()
  const {data: listApartment,isError: isErrorApartment,isSuccess:isSuccessApartment} = useFetchAllApartmentQuery()
  const {data: listBanner,isError: isErrorBanner} = useFetchAllBannerQuery()
  const apartmentReducer = useSelector((state) => state.apartmentSlice.apartments)
  useEffect(() => {
    if(listApartment){
      dispatch(fetchAllApartmentSlice(listApartment))
    }
  },[isSuccessApartment,listApartment])
  useEffect(() => {
    if(isErrorService){
      message.warning("Không thể hiện thị danh sách dịch vụ")
    }
    if(isErrorApartment){
      message.warning("Không thể hiện thị danh sách phòng ban")
    }
    if(isErrorBanner){
      message.warning("Không thể hiện thị banner")
    }
  },[isErrorService,isErrorApartment])
  return (
    <div>
      <Banner getBanner={listBanner && listBanner.length > 0 ? listBanner[0] : {}} />
      <Skills listService={listService ? listService : []} />
      <Projects apartmentReducer={apartmentReducer ? apartmentReducer : []} dispatch={dispatch} listApartment={listApartment ? listApartment : []} />
      <Contact />
    </div>
  )
}

export default HomeComponents
