import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../configs/api/api";

const bannerApi = createApi({
    reducerPath: "banner",
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
        credentials: 'include'
    }),
    tagTypes: ["banner"],
    endpoints: (builder) => ({
        fetchAllBanner: builder.query({
            query: () => `/api/get-all-banner`,
            providesTags: ["banner"]
        }),
        createBanner: builder.mutation({
            query: (body) => ({
                url: `/api/create-banner`,
                method: "POST",
                body: body
            }),
            invalidatesTags: ["banner"]
        }),
        updateBanner: builder.mutation({
            query: ({id,...body}) => ({
                url: `/api/get-update-banner/${id}`,
                method: "PATCH",
                body: body
            }),
            invalidatesTags: ["banner"]
        }),
        
    })
})

export const {useFetchAllBannerQuery,useCreateBannerMutation,useUpdateBannerMutation  } = bannerApi
export default bannerApi