import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../configs/api/api";

const articleService = createApi({
    reducerPath: "articleService",
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
        credentials: 'include'
    }),
    tagTypes: ["articleService"],
    endpoints: (builder) => ({
        fetchAllArticleService: builder.query({
            query: () => `/api/get-all-service-article`,
            providesTags: ["articleService"]
        }),
        getByArticleServiceId: builder.query({
            query: (id) => `/api/${id}/get-one-service-article`,
            providesTags: ["articleService"]
        }),
        createArticleService: builder.mutation({
            query: (data) => ({
                url: `/api/create-service-article`,
                method: "POST",
                body: data
            }),
            invalidatesTags: ["articleService"]
        }),
        updateArticleServiceById: builder.mutation({
            query: ({ id, ...data }) => ({
                url: `/api/${id}/update-service-article`,
                method: "PATCH",
                body: data
            }),
            invalidatesTags: ["articleService"]
        }),
        // Xoa theo id
        deleteArticleServiceById: builder.mutation({
            query: (id) => ({
                url: `/api/${id}/delete-service-article`,
                method: "DELETE"
            }),
            invalidatesTags: ["articleService"]
        }),
    })
})

export const { useFetchAllArticleServiceQuery, useLazyGetByArticleServiceIdQuery, useCreateArticleServiceMutation, useUpdateArticleServiceByIdMutation, useDeleteArticleServiceByIdMutation } = articleService
export default articleService