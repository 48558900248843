import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../configs/api/api";

const myServiceApi = createApi({
    reducerPath: "services",
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
        credentials: 'include'
    }),
    tagTypes: ["services"],
    endpoints: (builder) => ({
        fetchAllServices: builder.query({
            query: () => `/api/get-all-service`,
            providesTags: ["services"]
        }),
        getByServiceId: builder.query({
            query: (id) => `/api/${id}/get-one-service`,
            providesTags: ["services"]
        }),
        createCategories: builder.mutation({
            query: (data) => ({
                url: `/api/create-service`,
                method: "POST",
                body: data
            }),
            invalidatesTags: ["services"]
        }),
        updateServiceById: builder.mutation({
            query: (data) => ({
                url: `/api/update-service`,
                method: "PATCH",
                body: data
            }),
            invalidatesTags: ["services"]
        }),
        // Xoa theo id
        deleteServiceById: builder.mutation({
            query: (id) => ({
                url: `/api/${id}/delete-service`,
                method: "DELETE"
            }),
            invalidatesTags: ["services"]
        }),
    })
})

export const {useFetchAllServicesQuery,useLazyGetByServiceIdQuery,useDeleteServiceByIdMutation,useUpdateServiceByIdMutation,useCreateCategoriesMutation  } = myServiceApi
export default myServiceApi