import { createSlice } from "@reduxjs/toolkit";

export const initialStateApartment = {
    apartments: []
}

export const initialSearchState = {
    searchTerm: "",
    apartments: []
}

const apartmentSlice = createSlice({
    name: "apartments",
    initialState: initialStateApartment || initialSearchState,
    reducers: ({
        fetchAllApartmentSlice: (state, actions) => {
            state.apartments = actions.payload
        },
        fetchApartmentByIdSlice: (state, actions) => {
            state.apartments = state.apartments.filter((apartment) => apartment.id === actions.payload)
        },
    })
})

export const { fetchAllApartmentSlice, fetchApartmentByIdSlice } = apartmentSlice.actions
export default apartmentSlice.reducer