import { Button, Image, message, Modal, Popconfirm, Space, Table, Tooltip } from "antd";
import { useFetchAllBannerQuery } from "../../../store/banner/banner.service";
import { useState } from "react";
import ModalBannerCreate from "./ModalBannerCreate";
import ModalBannerUpdate from "./ModalBannerUpdate";
import { EditFilled } from "@ant-design/icons";



const BannerComponents = () => {
  const [open, setOpen] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const { data: listBanner, isError: isErrorBanner } = useFetchAllBannerQuery()
  const [getOneBanner,setBanner] = useState()
  if (isErrorBanner) {
    message.error("Lỗi khi hiển thị danh sách banner")
  }
  const data = listBanner ? listBanner.map((banner, index) => ({
    key: index + 1,
    id: banner.id,
    title: banner.title,
    description: banner.description
  })) : []
  const columns = [
    {
      title: 'STT',
      dataIndex: 'key',
    },
    {
      title: 'Tiêu đề',
      dataIndex: 'title',
    },
    {
      title: 'Nội dung',
      dataIndex: 'description',
    },
    {
      title: 'Hành động',
      key: 'action',
      width: 150,
      render: (_, value) => (
        <Space size="middle" className='flex justify-start'>
          <Tooltip title="Chỉnh sửa" color={'yellow'} key={'yellow'}>
            <EditFilled className='text-xl text-yellow-400' onClick={() => showModalUpdate(value)} />
          </Tooltip>
        </Space>
      )
    },
  ];
  const showModalUpdate = (value) => {
    if(value){
      setBanner(value)
    }
    setOpenUpdate(true)
  }
  const showModal = () => {
    setOpen(true)
  }
  return (
    <>
      <div className="flex items-center gap-2 justify-between">
        <h3 className='text-title mb-0'>Quản lí dịch vụ công ty</h3>
        <Button type='primary' className='mb-3' onClick={() => showModal()}>Thêm mới</Button>
      </div>
      <Table dataSource={data} columns={columns} />
      {/* modal create */}
      <ModalBannerCreate setOpen={setOpen} open={open}></ModalBannerCreate>
      {/* modal update */}
      <ModalBannerUpdate getOneBanner={getOneBanner ? getOneBanner : {}} setOpen={setOpenUpdate} open={openUpdate}></ModalBannerUpdate>
    </>
  )
}

export default BannerComponents
