import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { Button, Flex, Image, Tag } from "antd";
import { ArrowRightCircle } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllApartmentSlice, fetchApartmentByIdSlice } from "../store/apartment/apartmentSlice";
import { BASE_URL } from "../configs/api/api";

export const Projects = (props) => {

  const navigate = useNavigate()
  const { listApartment, apartmentReducer, dispatch } = props
  const arhticleReducer = useSelector((state) => state.arhticleByApartmentSlice.arhticles)
  const colors = [
    "#f50", "#2db7f5", "#87d068", "#108ee9",
    "#fa541c", "#9254de", "#13c2c2", "#eb2f96",
    "#fadb14", "#fa8c16", "#722ed1", "#52c41a",
    "#1890ff", "#d4380d", "#531dab", "#14b8a6",
    "#a855f7", "#f43f5e", "#10b981", "#f59e0b",
    "#818cf8", "#fb923c", "#f472b6", "#34d399",
    "#facc15", "#db2777", "#38bdf8", "#f87171",
    "#6ee7b7", "#60a5fa", "#c084fc", "#f97316",
  ];
  const getRandomColor = () => {
    return colors[Math.floor(Math.random() * colors.length)];
  };
  const tagsArr = [
    { id: 1, title: "Dịch Vụ 1", description: "Mô tả dịch vụ 1" },
    { id: 2, title: "Dịch Vụ 2", description: "Mô tả dịch vụ 2" },
    { id: 3, title: "Dịch Vụ 3", description: "Mô tả dịch vụ 3" },
    { id: 4, title: "Dịch Vụ 4", description: "Mô tả dịch vụ 4" },
  ];
  // 
  const fields = [
    {
      id: 1,
      title: "Giới thiệu về phòng tư vấn",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      category: 1,
      imgUrl: "https://www.shutterstock.com/image-vector/call-center-support-service-assistance-260nw-2082721189.jpg",
    },
    {
      id: 2,
      title: "Giới thiệu về phòng IT",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      category: 2,
      imgUrl: "https://img.timviecthietke.com/2023/03/coder-la-gi-2.jpg",
    },
  ];

  const handelNavigate = (id,name) => {
    if (id) {
      navigate(`/article-detail/${id}?${name}`)
    }
  }
  const handleNavLink = async (apartmentId) => {
    dispatch(fetchAllApartmentSlice(listApartment))
    dispatch(fetchApartmentByIdSlice(apartmentId))
  }
  return (
    <section className="project" id="projects">
      <Container>
        <Row className="">
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <h3 className="">Lĩnh vực</h3>
                  <p className="text-center">Lĩnh vực của công ty Lâm Hải.</p>
                  {listApartment.length > 0 && (
                  <Tab.Container id="projects-tabs" defaultActiveKey={`${listApartment[0]?.id}`}>
                    <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                      {listApartment.map((apartment, index) => (
                        <Nav.Item key={index} onClick={() => handleNavLink(apartment.id)}>
                          <Nav.Link className="cursor-pointer" eventKey={`${apartment.id}`} >{apartment.name}</Nav.Link>
                        </Nav.Item>
                      ))}
                    </Nav>
                    <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                      {
                        apartmentReducer[0]?.listArticle?.length > 0 ? apartmentReducer[0].listArticle.map((apartment, index) => {
                          return (
                            <Tab.Pane eventKey={`${apartment.apartment_id}`}>
                              <Row className="py-8">
                                <div className="grid grid-cols-3 gap-8">
                                  <div className="col-span-3 sm:col-span-2">
                                    <h4>{apartment.title}</h4>
                                    <p className="line-clamp-4">{apartment.describle}</p>
                                    <Flex gap="4px 0" wrap>
                                      {apartment?.listService && apartment?.listService.length > 0 ? apartment?.listService.map((tag) => (
                                        <>
                                          <Tag color={getRandomColor()}>{tag?.name}</Tag>
                                        </>
                                      )) : ""}
                                    </Flex>
                                    <button type="submit" className="flex gap-4 border mt-[30px]" onClick={() => handelNavigate(apartment.id,apartmentReducer[0].name)}><span>Tìm hiểu thêm </span> <span><ArrowRightCircle className="iconButton" size={25} /></span></button>
                                  </div>
                                  <div className="col-span-1 hidden sm:block">
                                    <img className="md:w-[200px] lg:w-[300px] md:h-[200px] lg:h-[300px] w-[150px] h-[150px] object-cover rounded-[50%] text-right" src={`${BASE_URL}/${apartment.image}`}></img>
                                  </div>
                                </div>
                              </Row>
                            </Tab.Pane>
                          )
                        })
                          : "Hiện không có dữ liệu"
                      }
                    </Tab.Content>
                  </Tab.Container>
                  )}
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
