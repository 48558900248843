import React from 'react'

const DashboardPage = () => {
  return (
    <div className='dashboard'>
      Dashboard
    </div>
  )
}

export default DashboardPage
