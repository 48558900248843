import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../configs/api/api";

const contactApi = createApi({
    reducerPath: "contacts",
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
        credentials: 'include'
    }),
    tagTypes: ["contacts"],
    endpoints: (builder) => ({
        fetchAllContact: builder.query({
            query: () => `/api/get-all-contact`,
            providesTags: ["contacts"]
        }),
        createContact: builder.mutation({
            query: (data) => ({
                url: `/api/create-contact`,
                method: "POST",
                body: data
            }),
            invalidatesTags: ["contacts"]
        }),
        updateStatusContactById: builder.mutation({
            query: ({ id, ...data }) => ({
                url: `/api/update-status-contact/${id}`,
                method: "PATCH",
                body: data
            }),
            invalidatesTags: ["contacts"]
        }),
        // Xoa theo id
        deleteContactById: builder.mutation({
            query: (id) => ({
                url: `/api/${id}/delete-contact`,
                method: "DELETE"
            }),
            invalidatesTags: ["contacts"]
        }),
    })
})

export const { useFetchAllContactQuery,useCreateContactMutation,useUpdateStatusContactByIdMutation,useDeleteContactByIdMutation } = contactApi
export default contactApi