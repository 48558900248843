import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../configs/api/api";

const apartmentApi = createApi({
    reducerPath: "apartments",
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
        credentials: 'include'
    }),
    tagTypes: ["apartments"],
    endpoints: (builder) => ({
        fetchAllApartment: builder.query({
            query: () => `/api/get-all-apartment`,
            providesTags: ["apartments"]
        }),
        getByApartmentId: builder.query({
            query: (id) => `/api/${id}/get-one-apartment`,
            providesTags: ["apartments"]
        }),
        createApartments: builder.mutation({
            query: (data) => ({
                url: `/api/create-apartment`,
                method: "POST",
                body: data
            }),
            invalidatesTags: ["apartments"]
        }),
        updateApartmentById: builder.mutation({
            query: ({id,...data}) => ({
                url: `/api/${id}/update-apartment`,
                method: "PATCH",
                body: data
            }),
            invalidatesTags: ["apartments"]
        }),
        // Xoa theo id
        deleteApartmentById: builder.mutation({
            query: (id) => ({
                url: `/api/${id}/delete-apartment`,
                method: "DELETE"
            }),
            invalidatesTags: ["apartments"]
        }),
    })
})

export const {useFetchAllApartmentQuery,useLazyGetByApartmentIdQuery,useUpdateApartmentByIdMutation,useDeleteApartmentByIdMutation,useCreateApartmentsMutation} = apartmentApi
export default apartmentApi