import { ArrowLeftOutlined, UpOutlined } from '@ant-design/icons'
import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useLazyGetByArticleServiceIdQuery } from '../../../store/article_service/articleService.service'
import { message, Spin, Card, Typography, Image, FloatButton } from 'antd'

const { Title } = Typography

const ServiceComponents = () => {
  const { id } = useParams()
  const [trigger, { data: getOneService, isError, isLoading, error }] = useLazyGetByArticleServiceIdQuery()
  console.log(`getOneService:`, getOneService)
  
  useEffect(() => {
    if (isError) {
      message.error(error?.data?.message || "Hiện có lỗi xảy ra")
    }
  }, [isError, error])

  useEffect(() => {
    if (id) {
      trigger(id)
    }
  }, [id, trigger])
  
  const handleBackToTop = () => {
    const topElement = document.getElementById('top');
    console.log(`topElement:`,topElement)
    if (topElement) {
      topElement.scrollIntoView({ behavior: 'smooth' });
    }
  }
  
  return (
    <>
      {/* Đặt phần tử top ở đây */}
      <div className='absolute top-6 left-6 right-6 flex justify-between items-center z-10'>
        <Link to={`/article-detail/${getOneService?.article_id}?${getOneService?.apartment_name}`}>
          <ArrowLeftOutlined />
        </Link>
        <Link to="/">Trang chủ</Link>
        <FloatButton 
          icon={<UpOutlined />} 
          onClick={handleBackToTop} 
        />
      </div>
      <div className='service'>
        <div className='container'>
          {isLoading ? (
            <div className='spinner'>
              <Spin tip="Đang tải..." size="large" />
            </div>
          ) : getOneService ? (
            <Card
              title={<Title className='text-white'  id="top" level={2}>{getOneService?.name || "Tiêu đề bài viết"}</Title>}
              cover={
                getOneService?.image_url && (
                  <Image
                    alt={getOneService?.name}
                    src={getOneService?.image_url}
                    fallback="https://via.placeholder.com/600x400?text=No+Image"
                  />
                )
              }
              bordered={false}
              className='service_card'
            >
              <Typography>
                <div
                  className='content text-white'
                  dangerouslySetInnerHTML={{ __html: getOneService?.content || "Hiện không có bài viết" }}
                ></div>
              </Typography>
            </Card>
          ) : (
            <div className='no-data'>
              <Title level={4}>Không tìm thấy bài viết</Title>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default React.memo(ServiceComponents)
