import { ArrowLeftOutlined, LoginOutlined, LogoutOutlined } from '@ant-design/icons'
import { Card, Col, message, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { ArrowDownRight, ArrowRight } from 'react-bootstrap-icons'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useLazyGetByArticleIdQuery } from '../../../store/articles/articles.service'

const FieldDetailComponent = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [trigger, { data: getOneArticle, isError: errGetOneArticle }] = useLazyGetByArticleIdQuery()
  console.log(`getOneArticle:`,getOneArticle)
  useEffect(() => {
    trigger(id)
  }, [id])
  if (errGetOneArticle) {
    message.warning("Lỗi hiển thị bài viết")
  }
  // Dữ liệu cho các box
  const boxesData = [
    {
      id: 1,
      number: '01',
      title: 'Phòng tư vấn',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua...',
    },
    {
      id: 2,
      number: '02',
      title: 'Phòng hỗ trợ',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua...',
    },
    {
      id: 3,
      number: '03',
      title: 'Phòng kỹ thuật',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua...',
    },
    {
      id: 4,
      number: '04',
      title: 'Phòng marketing',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua...',
    },
  ];
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  }, [navigate])
  // Thêm state để quản lý trạng thái hover
  const [isHovered, setIsHovered] = useState(false);

  // State để quản lý hover cho từng box bằng cách sử dụng id
  const [hoveredBoxId, setHoveredBoxId] = useState(null);

  const handleMouseEnter = (id) => {
    setHoveredBoxId(id);
  };

  const handleMouseLeave = () => {
    setHoveredBoxId(null);
  };
  const HandleNavigate = (id, name) => {
    navigate(`/service-detail/${id}?${name}`)
  }
  return (
    <>
      <div className='absolute top-6 left-6 right-6 flex justify-between items-center'>
        <Link to="/"><ArrowLeftOutlined className='text-xl left-6' /></Link>
        <h1 className='text-center text-xl'>{getOneArticle?.apartment_name}</h1>
      </div>
      <div className='field-detail flex items-center justify-end md:px-[80px] xl:px-[200px]'>
        <div className='container'>
         
          <Row gutter={40}>
            {getOneArticle?.listService.length > 0 ? getOneArticle?.listService.map((box, index) => (
              <Col xs={24} md={12}  className="" key={box.id}>
                <div
                  onClick={() => HandleNavigate(box.id, box.name)}
                  className="field-detail_box border-b"
                  onMouseEnter={() => handleMouseEnter(box.id)}
                  onMouseLeave={handleMouseLeave}
                >
                  <div className="text-[32px] flex justify-between items-center field-detail_head">
                    <div className="field-detail_icon relative transition-all duration-300 ease-linear">
                      {/* <ArrowRight className={`absolute ${hoveredBoxId === box.id ? 'opacity-100' : 'opacity-0'}`} />
                      <ArrowDownRight className={`absolute ${hoveredBoxId === box.id ? 'opacity-0' : 'opacity-100'}`} /> */}
                    </div>
                  </div>
                  <h1 className="text-[24px] tracking-wide my-3 field-detail_title line-clamp-2">{box.name}</h1>
                  <p className="line-clamp-3 min-h-[55px] field-detail_description">{box.describle}</p>
                </div>
              </Col>
            )) : <p className='text-center'>Hiện không có dữ liệu</p>}
          </Row>
        </div>
      </div>
    </>
  )
}

export default FieldDetailComponent
