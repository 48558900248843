import { message, Popconfirm, Space, Table, Tooltip } from 'antd';
import React from 'react'
import { useDeleteContactByIdMutation, useFetchAllContactQuery, useUpdateStatusContactByIdMutation } from '../../../store/contact/contact.service';
import { CheckSquareOutlined, CloseSquareOutlined, DeleteFilled } from '@ant-design/icons';
import Swal from 'sweetalert2';

const ContactComponents = () => {
    const { data: listContact, isError } = useFetchAllContactQuery()
    const [onDelete] = useDeleteContactByIdMutation()
    const [onUpdateStatus] = useUpdateStatusContactByIdMutation()
    if (isError) {
        message.error("Có lỗi khi hiển thị danh sách liên hệ")
    }
    const data = listContact ? listContact.map((contact, index) => ({
        key: index + 1,
        id: contact.id,
        full_name: contact.full_name,
        email: contact.email,
        tel: contact.tel,
        message: contact.message,
        status: contact.status
    })) : []

    const columns = [
        {
            title: 'STT',
            dataIndex: 'key',
        },
        {
            title: 'Tên khách hàng',
            dataIndex: 'full_name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
        },
        {
            title: 'Số điện thoại',
            dataIndex: 'tel',
        },
        {
            title: 'Lời nhắn',
            dataIndex: 'message',
        },
        {
            title: 'Trạng thái',
            key: 'status',
            width: 150,
            render: (_, value) => (
                <>
                    {!value.status ?
                        <Space>
                            <CloseSquareOutlined className='text-red-500 text-xl cursor-pointer' onClick={() => handleUnActive(value.id, value.full_name)} />
                            <CheckSquareOutlined className='text-green-500 text-xl cursor-pointer' onClick={() => handleActive(value.id, value.full_name)} />
                        </Space>
                        :
                        <>
                            {value.status === 1 && <p className='text-green-500 font-semibold'>Đã duyệt</p>}
                            {value.status === 2 && <p className='text-red-500 font-semibold'>Đã hủy</p>}
                        </>
                    }
                </>
            )
        },
        {
            title: 'Hành động',
            key: 'action',
            width: 150,
            render: (_, value) => (
                <Space size="middle" className='flex justify-start'>
                    <Popconfirm
                        title="Xóa"
                        description={`Bạn có chắc muốn xóa: ${value.full_name}`}
                        onConfirm={() => handleDelete(value.id)}
                        okText="Yes"
                        cancelText="No"
                        okButtonProps={{ className: "text-white bg-blue-500" }}
                    >
                        <Tooltip title="Xóa" color={'red'} key={'red'}>
                            <DeleteFilled className='text-xl text-red-500' />
                        </Tooltip>
                    </Popconfirm>
                </Space>
            )
        },
    ];
    const handleUnActive = async (id, full_name) => {
        try {
            Swal.fire({
                title: "Xác nhận ?",
                text: `Xác nhận khách hàng ${full_name}`,
                showCancelButton: true,
                cancelButtonText: "Hủy",
                confirmButtonColor: "#EF4444",
                confirmButtonText: "Xác nhận",
                icon: "question",
                iconColor: "red"
            }).then(async (result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    const results = await onUpdateStatus({ id: id, status: 2 })
                    if (results.error) {
                        return message.error(`${results.error.data.message}`)
                    }
                    message.success("Đã xác nhận khách hàng")
                } else if (result.isDenied) {
                    Swal.fire("Changes are not saved", "", "info");
                }
            });
        } catch (error) {
            console.log(error)
        }
    }
    // nút duyệt
    const handleActive = async (id, full_name) => {
        try {
            Swal.fire({
                title: "Xác nhận ?",
                text: `Xác nhận khách hàng ${full_name}`,
                showCancelButton: true,
                cancelButtonText: "Hủy",
                confirmButtonColor: "#22C55E",
                confirmButtonText: "Xác nhận",
                icon: "question",
                iconColor: "#22C55E"
            }).then(async (result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    const results = await onUpdateStatus({ id: id, status: 1 })
                    if (results.error) {
                        return message.error(`${results.error.data.message}`)
                    }
                    Swal.fire({
                        icon: "success",
                        title: "Đã xác nhận",
                        showConfirmButton: false,
                        timer: 1000
                    });
                } else if (result.isDenied) {
                    Swal.fire("Changes are not saved", "", "info");
                }
            });
        } catch (error) {
            console.log(error)
        }
    }
    const handleDelete = async (id) => {
        const results = await onDelete(id)
        if (results.error) {
            message.error(`${results.error.data.message}`)
            return
        }
        message.success(`${results.data.message}`);
    }
    return (
        <div>
            <h3 className='text-title mb-0'>Quản lí liên hệ khách hàng</h3>
            <Table dataSource={data} columns={columns} />;
        </div>
    )
}

export default ContactComponents
