import { configureStore } from '@reduxjs/toolkit';
import myServiceApi from './myService/service.service';
import apartmentApi from './apartment/apartment.service';
import articleService from './article_service/articleService.service';
import articleApi from './articles/articles.service';
import arhticleByApartmentSlice from "./articles/articleSlice"
import apartmentSlice from "./apartment/apartmentSlice"
import contactApi from './contact/contact.service';
import bannerApi from './banner/banner.service';
export const store = configureStore({
  reducer: {
    [myServiceApi.reducerPath]: myServiceApi.reducer,
    [apartmentApi.reducerPath]: apartmentApi.reducer,
    [articleService.reducerPath]: articleService.reducer,
    [articleApi.reducerPath]: articleApi.reducer,
    [contactApi.reducerPath]: contactApi.reducer,
    [bannerApi.reducerPath]: bannerApi.reducer,
    arhticleByApartmentSlice:arhticleByApartmentSlice,
    apartmentSlice:apartmentSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      myServiceApi.middleware,
      apartmentApi.middleware,
      articleService.middleware,
      articleApi.middleware,
      contactApi.middleware,
      bannerApi.middleware
    ),
});

export default store;