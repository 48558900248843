import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/header-img.svg";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { Link, useNavigate } from "react-router-dom";

export const Banner = (props) => {
  const {getBanner} = props
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = [`${getBanner ? getBanner.title : "Công Ty Lâm Hải"}`];
  const period = 1000;
  const navigate = useNavigate()
  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }
  const handelNavigate = () => {
    window.open("https://lamhai.com.vn/","_blank")
  }
  return (
    <section className="banner" id="home">
      <Container>
        <Row className="">
          <Col xs={12} md={7} xl={7}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <span className="tagline">Giới thiệu về chúng tôi</span>
                  <h1 className="">{`Chào mừng đến với`} <span className="txt-rotate" dataPeriod="100" data-rotate={`[${getBanner ? getBanner.title : "Công Ty Lâm Hải"}]`}><span className="wrap">{text}</span></span></h1>
                  <p className="">{getBanner ? getBanner.description : "Hiện không có nội dung."}</p>
                  <button onClick={() => handelNavigate()}>Tới với website chúng tôi <ArrowRightCircle size={25} /></button>
                </div>}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={5} xl={5} className="hidden md:block">
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={`${isVisible ? "animate__animated animate__zoomIn" : ""}`}>
                  <img className="" src={headerImg} alt="Header Img" />
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
