// src/App.js
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, Routes, useLocation } from 'react-router-dom';
import LayoutClients from "./layout/clients/index";
import { privateRoutes, publicRoutes } from './routes';
import { AnimatePresence, motion } from 'framer-motion';
import "./App.css"
import ServiceComponents from './pages/clients/MyService/MyServiceComponents';
import AdminLayout from './layout/admin/AdminLayout';
import FieldDetailComponent from './pages/clients/ArticleDetail/FieldDetailComponent';
import PageNotFound from './pages/PageNotFound';

function App() {
  const location = useLocation();
  const prefersReducedMotion = window.matchMedia("(prefers-reduced-motion: reduce)").matches;

  const pageVariants = {
    initial: {
      opacity: 0,
      x: prefersReducedMotion ? 0 : 100,
      y: prefersReducedMotion ? 0 : 50,
    },
    in: {
      opacity: 1,
      x: 0,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeInOut",
      },
    },
    out: {
      opacity: 0,
      x: prefersReducedMotion ? 0 : -100,
      y: prefersReducedMotion ? 0 : -50,
      transition: {
        duration: 0.5,
        ease: "easeInOut",
      },
    },
  };

  const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.5,
  };

  return (
    <div className="App">
      {/* client */}
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          {/* routes những dịch vụ của phòng */}
          <Route path='/article-detail/:id' element={<motion.div
            layout
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}
          >
            <FieldDetailComponent></FieldDetailComponent>
          </motion.div>}></Route>
          {/* Cac dich vu */}
          <Route path='/service-detail/:id' element={<motion.div
            layout
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}
          >
            <ServiceComponents></ServiceComponents>
          </motion.div>}></Route>
         
          {publicRoutes?.map((route, index) => {
            const Page = route.Component;
            return (
              <Route
                key={index}
                path={route.path}
                element={
                  <LayoutClients>
                    <motion.div
                      layout
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}
                    >
                      <Page />
                    </motion.div>
                  </LayoutClients>
                }
              />
            );
          })}
          
        </Routes>
      </AnimatePresence>
      {/* Admin */}
      <Routes>
     
        {privateRoutes?.map((route, index) => {
          const Page = route.Component;
          return (
            <Route
              key={index}
              path={route.path}
              element={
                <AdminLayout>
                  <Page />
                </AdminLayout>
              }
            />
          );
        })
        }
      </Routes>
    </div>
  );
}

export default App;
