import { Button, Layout, theme } from 'antd'
import React, { useState } from 'react'
import MenuComponents from './Menu'
import { Content, Header } from 'antd/es/layout/layout'
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'

const AdminLayout = ({ children }) => {
    const navigate = useNavigate()
    const [collapsed, setCollapsed] = useState(false);
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();
  return (
    <div>
       <Layout>
                <MenuComponents collapsed={collapsed}></MenuComponents>
                <Layout>
                    <Header className='flex items-center justify-between' style={{ padding: 0, background: colorBgContainer }}>
                        <Button
                            type="text"
                            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                            onClick={() => setCollapsed(!collapsed)}
                            style={{
                                fontSize: '16px',
                                width: 64,
                                height: 64,
                            }}
                        />

                    </Header>
                    <Content
                        style={{
                            margin: '24px 16px',
                            padding: 24,
                            maxHeight: 590,
                            overflowY: "auto",
                            scrollbarWidth: 'none', // Ẩn thanh cuộn trên Firefox
                            msOverflowStyle: 'none', // Ẩn thanh cuộn trên Internet Explorer và Edge
                            background: colorBgContainer,
                            borderRadius: borderRadiusLG,
                        }}
                    >
                        {children}
                    </Content>
                </Layout>
            </Layout>
    </div>
  )
}

export default AdminLayout
