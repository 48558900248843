import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { API_KEY } from '../../configs/api/api';
import imageCompression from 'browser-image-compression';
const TinyMCEEditor = ({ value, onChange }) => {
    const editorRef = useRef(null);

    const handleImageUpload = async (blobInfo) => {
        try {
            const file = blobInfo.blob();
            console.log(file)
            const options = {
                maxSizeMB: 2, // Kích thước tối đa 1MB
                maxWidthOrHeight: 1000, // Chiều rộng hoặc chiều cao tối đa 1024px
                initialQuality: 1, // Tăng chất lượng nén
                useWebWorker: true,
            };
            const compressedFile = await imageCompression(file, options);
            const reader = new FileReader();

            return new Promise((resolve, reject) => {
                reader.readAsDataURL(compressedFile);
                reader.onload = () => {
                    const base64 = reader.result.split(',')[1];
                    resolve(`data:${compressedFile.type};base64,${base64}`);
                };
                reader.onerror = () => {
                    reject('Không thể tải lên hình ảnh.');
                };
            });
        } catch (error) {
            console.error('Error compressing image:', error);
            throw new Error('Không thể tải lên hình ảnh.');
        }
    };

    return (
        <Editor
            apiKey={API_KEY} // Đảm bảo API Key hợp lệ
            onInit={(evt, editor) => (editorRef.current = editor)}
            value={value}
            init={{
                height: 500,
                menubar: true,
                plugins: 'advlist autolink lists link image charmap preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code help wordcount imagetools',
                toolbar: 'undo redo | bold italic | image | alignleft aligncenter alignright',
                image_title: true, // Cho phép thêm tiêu đề hình ảnh
                automatic_uploads: true,
                file_picker_types: 'image',
                // Loại bỏ images_upload_url vì không sử dụng API backend
                // Sử dụng images_upload_handler để xử lý tải lên hình ảnh
                images_upload_handler: handleImageUpload,
                content_style: `
                    body { font-family:Helvetica,Arial,sans-serif; font-size:14px }
                    img { max-width: 100%; height: 100%;object-fit: cover;  }
                `,
            }}
            onEditorChange={(content, editor) => {
                onChange(content);
            }}
        />
    );
};

export default TinyMCEEditor;
