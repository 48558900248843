import { DeleteFilled, EditFilled } from "@ant-design/icons";
import { Button, Image, message, Modal, Popconfirm, Space, Table, Tooltip } from "antd";
import { useEffect, useState } from "react";
import ModalServiceCreate from "./ModalCreateField";
import ModalServiceUpdate from "./ModalUpdateField";
import { useDeleteServiceByIdMutation, useFetchAllServicesQuery, useLazyGetByServiceIdQuery } from "../../../store/myService/service.service";
import { BASE_URL } from "../../../configs/api/api";


const ServiceComponents = () => {
    const { data: listService, isError: isErrorListService } = useFetchAllServicesQuery()
    const [trigger, { data: getOneService, isError: isErrorGetOneService }] = useLazyGetByServiceIdQuery()
    const [onDelete] = useDeleteServiceByIdMutation()
    // modal state 
    useEffect(() => {
        if (isErrorListService || isErrorGetOneService) {
            message.warning("Lỗi khi hiển thị danh sách dịch vụ");
        }
    }, [isErrorListService, isErrorGetOneService]); // Chạy effect khi isErrorListService thay đổi
    const [open, setOpen] = useState(false);
    const [openUpdate, setOpenUpdate] = useState(false);
    const data = listService ? listService.map((service, index) => ({
        key: index + 1,
        id: service.id,
        title: service.title,
        image: service.image
    })) : []
    const columns = [
        {
            title: 'STT',
            dataIndex: 'key',
        },
        {
            title: 'Tên dịch vụ công ty',
            dataIndex: 'title',
        },
        {
            title: 'Ảnh',
            dataIndex: 'image',
            render: (_, value) => (
                <>
                    <Image className="rounded-[50%]" src={`${BASE_URL}/${value.image}`} width={80} height={80}></Image>
                </>
            )
        },
        {
            title: 'Hành động',
            key: 'action',
            width: 150,
            render: (_, value) => (
                <Space size="middle" className='flex justify-start'>
                    <Tooltip title="Chỉnh sửa" color={'yellow'} key={'yellow'}>
                        <EditFilled className='text-xl text-yellow-400' onClick={() => showModalUpdate(value.id)} />
                    </Tooltip>
                    <Popconfirm
                        title="Xóa"
                        description={`Bạn có chắc muốn xóa: ${value.title}`}
                        onConfirm={() => handleDelete(value.id)}
                        okText="Yes"
                        cancelText="No"
                        okButtonProps={{ className: "text-white bg-blue-500" }}
                    >
                        <Tooltip title="Xóa" color={'red'} key={'red'}>
                            <DeleteFilled className='text-xl text-red-500' />
                        </Tooltip>
                    </Popconfirm>
                </Space>
            )
        },
    ];
    // handle xoa
    const handleDelete = async (id) => {
        const results = await onDelete(id)
        if (results.error) {
            message.error(`${results.error.data.message}`)
            return
        }
        message.success(`${results.data.message}`);
    }
    // create modal
    const showModal = () => {
        setOpen(true);
    };
    // update modal
    const showModalUpdate = (id) => {
        if (id) {
            trigger(id)
        }
        setOpenUpdate(true);
    };

    return (
        <>
            <div className="flex items-center gap-2 justify-between">
                <h3 className='text-title mb-0'>Quản lí dịch vụ công ty</h3>
                <Button type='primary' className='mb-3' onClick={() => showModal()}>Thêm mới</Button>
            </div>
            <Table dataSource={data} columns={columns} />
            {/* modal create */}
            <ModalServiceCreate setOpen={setOpen} open={open}></ModalServiceCreate>
            <ModalServiceUpdate getOneService={getOneService ? getOneService : {}} setOpenUpdate={setOpenUpdate} openUpdate={openUpdate}></ModalServiceUpdate>
        </>
    )
}

export default ServiceComponents
