import React from 'react'
import { NavBar } from "../../components/NavBar";
import { Footer } from '../../components/Footer';
const LayoutClients = ({ children }) => {
  return (
    <div>
      <NavBar />
      {children}
      <Footer />
    </div>
  )
}

export default LayoutClients
