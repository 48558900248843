import React, { useEffect, useState } from 'react'
import TextArea from 'antd/es/input/TextArea';
import { Badge, Button, Col, Form, FormInstance, Input, message, Modal, Popconfirm, Result, Row, Space, Spin, Switch, Table, Tooltip, Upload } from 'antd';
import { SaveOutlined, UploadOutlined } from '@ant-design/icons';
import { useCreateCategoriesMutation } from '../../../store/myService/service.service';

const SubmitButton = ({ form }) => {
    const [submittable, setSubmittable] = React.useState(false);
    const values = Form.useWatch([], form);

    React.useEffect(() => {
        form.validateFields({ validateOnly: true }).then(
            () => {
                setSubmittable(true);
            },
            () => {
                setSubmittable(false);
            },
        );
    }, [values]);

    return (
        <Button type="primary" htmlType="submit" disabled={!submittable} className='bg-blue-500'>
            <SaveOutlined></SaveOutlined> Lưu thông tin
        </Button>
    );
};


const ModalFieldCreate = (props) => {
    // form
    const [form] = Form.useForm()
    const { open, setOpen } = props
    const [onAdd] = useCreateCategoriesMutation()
    const [file, setFile] = useState([]);
    const handleCancel = () => {
        setOpen(false);
        form.resetFields()
    };

    // submit add the loai
    const onFinish = async (values) => {
        try {
            const formData = new FormData();
            formData.append("title", values.title)
            file.forEach((item) => {
                formData.append("image", item.originFileObj)
            })
            const results = await onAdd(formData)
            if (results.error) {
                message.error(`${results.error.data.message}`)
                return
            }
            message.success(`${results.data.message}`);
            form.resetFields()
            setOpen(false);
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        if (open) {
            form.setFieldsValue({
                isActive: true,
                IsDeleted: false
            })
        }
    }, [open])
    // modal
    const handleOk = () => {
        setOpen(false);
    };
    // ảnh đại diện

    const handleFileChangeFile = ({ fileList }) => setFile(fileList);
    return (
        <div>
            {/* modal them */}
            <Modal
                title="Thêm mới dịch vụ công ty"
                open={open}
                width={800}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
            >
                <Form
                    form={form}
                    name="validateOnly"
                    layout="vertical"
                    style={{
                        width: "100%",
                        margin: 0,
                        padding: "20px"
                    }}
                    autoComplete="off"
                    onFinish={onFinish}
                    className="mx-auto"
                >
                    <Row gutter={25}>
                        <Col span={24}>
                            <Form.Item name="title" label="Tên dịch vụ" rules={[
                                { required: true, message: '* Không được để trống' }
                            ]}>
                                <Input placeholder='Tên dịch vụ ...' />
                            </Form.Item>
                        </Col>
                        <Col span={20}>
                            <Form.Item
                                label="Ảnh bài viết"
                                name="image"
                                valuePropName="fileList"
                                getValueFromEvent={(e) => e.fileList}
                            >
                                <Upload
                                    // multiple
                                    maxCount={1}
                                    listType="picture-card"
                                    fileList={file}
                                    beforeUpload={() => false}  // Không upload ngay lập tức
                                    onChange={handleFileChangeFile}
                                >
                                    <Button icon={<UploadOutlined />}></Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Space style={{
                        display: "flex",
                        justifyContent: "flex-end"
                    }}>
                        <SubmitButton form={form} />
                    </Space>
                </Form>
            </Modal>
        </div>
    )
}

export default ModalFieldCreate
