import HomeComponents from "../pages/clients";
import { routes, routesAdmin } from "../configs/routes"
import DashboardPage from "../pages/admin";
import ApartmentComponents from "../pages/admin/Apartments";
import ServiceComponents from "../pages/admin/service_component";
import ServiceArticleComponents from "../pages/admin/service_article";
import ArticleComponents from "../pages/admin/Articles";
import ContactComponents from "../pages/admin/Contacts";
import PageNotFound from "../pages/PageNotFound";
import BannerComponents from "../pages/admin/banner";
export const publicRoutes = [
    { path: routes.home, Component: HomeComponents },
   
]
export const privateRoutes = [
    { path: routesAdmin.dashboard, Component: DashboardPage },
    { path: routesAdmin.adminServiceArticle, Component: ServiceArticleComponents },
    { path: routesAdmin.adminServices, Component: ServiceComponents },
    { path: routesAdmin.adminApartments, Component: ApartmentComponents },
    { path: routesAdmin.adminArticles, Component: ArticleComponents },
    { path: routesAdmin.adminContacts, Component: ContactComponents },
    { path: routesAdmin.adminBanner, Component: BannerComponents },
]